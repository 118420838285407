.main_sec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 80vh;
}


.sub_sec1 {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.sub_sec1_s1 {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sub_sec1_s2 {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sub_sec2 {
    flex: 1.3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lavni-logo {
    width: 170px;
}

.highlight-image {
    transition: filter 0.3s;
}

.space {
    width: 50px;
    height: 50px;
}

.drop-text {
    padding-top: 15px;
}

.image-container:hover .highlight-image {
    filter: brightness(1.2);
}

.three-d-avatar-image {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    cursor: pointer;
    border: 3px solid #efefef !important;
}

.active {
    border: 3px solid #ff8000 !important;
}

.avatar-div {
    text-align: center;
    height: 675px;
    width: 500px;
    margin: 0 auto;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: linear-gradient(45deg, #FFEE97, #FF8F05);
}

.camera-feed {
    display: none;
    opacity: 1;
    /* height: 1px; */
}

.loading-avatar {
    font-size: 20px;
}

@media only screen and (max-width: 1024px) {
    .sub_sec1_s2 {
        height: 200px;
    }

    .main_sec {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .three-d-avatar-image {
        width: 100px;
        height: 100px;
    }
}